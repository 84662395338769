const defaultError = require('../pages/error').default;

// The top-level (parent) route
const routes = {

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ '../pages/login'),
    },
    {
      path: '/forgot-password',
      load: () => import(/* webpackChunkName: 'forgot-password' */ '../pages/forgot-password'),
    },
    {
      path: '/update-password',
      load: () => import(/* webpackChunkName: 'update-password' */ '../pages/update-password'),
    },
    {
      path: '/register-account',
      load: () => import(/* webpackChunkName: 'register-account' */ '../pages/register-account'),
    },
    {
      path: '/welcome/:id',
      load: () => import(/* webpackChunkName: 'welcome' */ '../pages/welcome'),
    },
    {
      path: '/settings',
      children: [
        {
          path: '/contacts',
          protected: true,
          load: () => import(/* webpackChunkName: 'contacts' */ '../pages/settings/contacts'),
        },
        {
          path: '/payments',
          protected: true,
          load: () => import(/* webpackChunkName: 'payments' */ '../pages/settings/payments'),
        },
        {
          path: '/language',
          protected: true,
          load: () => import(/* webpackChunkName: 'language' */ '../pages/settings/language'),
        },
        {
          path: '/password',
          protected: true,
          load: () => import(/* webpackChunkName: 'password' */ '../pages/settings/password'),
        },
        {
          path: '/security-questions',
          protected: true,
          load: () => import(/* webpackChunkName: 'security-questions' */ '../pages/settings/security-questions'),
        },
        {
          path: '',
          protected: true,
          load: () => import(/* webpackChunkName: 'settings' */ '../pages/settings'),
        },
      ],
    },
    {
      path: '/my-units',
      children: [
        {
          path: '/payments',
          protected: true,
          load: () => import(/* webpackChunkName: 'payments' */ '../pages/my-units/payments'),
        },
        {
          path: '/invoices',
          protected: true,
          load: () => import(/* webpackChunkName: 'invoices' */ '../pages/my-units/invoices'),
        },
        {
          path: '',
          protected: true,
          load: () => import(/* webpackChunkName: 'my-units' */ '../pages/my-units'),
        },
      ],
    },
    {
      path: '',
      protected: true,
      load: () => import(/* webpackChunkName: 'pay' */ '../pages/pay'),
    },
    //
    // // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ '../pages/not-found'),
    },
  ],
  path: '',

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = route.title || '';
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) { // eslint-disable-line no-undef
  routes.children.unshift({
    path: '/error',
    action: defaultError,
  });
}

export default routes;
